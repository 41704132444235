<template>
  <v-row>
        <v-col cols="12">
       <v-card flat outlined :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
         <v-card-text>
           <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <!-- <v-col cols="12" md="3">
              <DropDownList dense :title="'ประเภท'" :rules="formTypeRules" :placeholder="'ประเภท'" :required="true" :items="formTypeItems" item-text="formTypeName" return-object v-model="formType"/>
            </v-col>
             <v-col cols="12" md="3">
              <DropDownList dense :title="'ประจำปี'" :rules="yearRules" :placeholder="'โปรดเลือก'" :required="true" :items="yearItems" v-model="year" item-text="yearTH" item-value="yearEN"/>
            </v-col>
             <v-col cols="12" md="3">
              <TextField dense number v-model="target" :title="'เป้าหมาย'" :placeholder="'เป้าหมาย'"/>
            </v-col> -->
            <v-spacer/>
             <v-col cols="12" md="3">
                <label class="text-title-4 natural--text text--darken-4">ดำเนินการ
              <v-btn color="primary"  block @click="onOpenCreateContentDialog()" :loading="loading">เพิ่มเนื้อหา</v-btn>
               </label>
            </v-col>
          </v-row>
           </v-form>
         </v-card-text>
       </v-card>
        </v-col>
         <v-col cols="12">
       <v-card flat outlined :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
         <v-card-text>
        <v-data-table :loading="loading" :items="targetItems" :headers="headers">
          <template slot="no-data">
              <div class="text-center text-title-3 natural--text text--lighten-1 pt-2">
              ไม่มีข้อมูลเป้าหมาย
            </div>
          </template>
             <!-- <template v-slot:item.action="{ item }">
              <v-row align="center" v-if="!item.editable">
                <v-btn icon @click="item.editable = true">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-row>
              <template v-else>
                 <v-row align="center">
                    <v-spacer/>
              <v-btn color="primary" width="70">บันทึก</v-btn>
              <v-spacer/>
              <v-btn outlined color="primary" width="70"  @click="item.editable = false">ยกเลิก</v-btn>
               <v-spacer/>
                 </v-row>
              </template>
            </template>
             <template v-slot:item.target="{ item }">
               <template v-if="item.editable">
                 <div class="pt-4">
               <TextField dense v-model="item.target"/>
                 </div>
               </template>
               <template v-else>
                {{item.target}}
               </template>
             </template>
               <template v-slot:item.delete="{ item }">
                 <v-btn icon @click="onDelete(item)"><v-icon>mdi-delete</v-icon></v-btn>
               </template> -->
        </v-data-table>
         </v-card-text>
       </v-card>
         </v-col>
          <v-dialog v-model="confirmDeleteDialog" width="384" persistent>
           <ConfirmDeleteCard :title="'คุณต้องลบรายการนี้หรือไม่?'" :subtitle="''" :action="'ลบข้อมูลนี้'" @onClose="confirmDeleteDialog=false" @onConfirm="deleteSiteTarget()" />
        </v-dialog>
        <v-dialog v-model="createContentDialog" width="384" persistent scrollable>
          <SiteContentCreateCard @onClose="createContentDialog=false" v-model="content" @onSubmit="postSiteContentType()" :dialog="createContentDialog"/>
        </v-dialog>
      </v-row>
</template>

<script>
// import TextField from '@/components/common/TextField'
// import DropDownList from '@/components/common/DropDownList'
import { getSiteContentType, postSiteContentType } from '@/api/'
import ConfirmDeleteCard from '@/components/common/ConfirmDeleteCard'
import SiteContentCreateCard from '@/components/site/SiteContentCreateCard'

export default {
  components: {
    // TextField,
    // DropDownList,
    ConfirmDeleteCard,
    SiteContentCreateCard
  },
  data () {
    return {
      loading: false,
      targetItems: [],
      headers: [
        { text: 'ประเภทเนื้อหา', value: 'contentType', class: 'primary lighten-5' },
        { text: 'เวอร์ชั่น', value: 'version', class: 'primary lighten-5', width: '100px' },
        { text: 'เมื่อ', value: 'versionDate', class: 'primary lighten-5', width: '150px' },
        { text: 'รูปแบบ', value: 'consentType', class: 'primary lighten-5', width: '200px' },
        { text: 'เนื้อหา', value: 'contentTh', class: 'primary lighten-5', width: '300px' }
        // { text: 'แก้ไข', value: 'action', class: 'primary lighten-5', width: '200px' },
        // { text: 'ลบ', value: 'delete', class: 'primary lighten-5', width: '100px' }
      ],
      valid: false,
      confirmDeleteDialog: false,
      currentItem: null,
      createContentDialog: false,
      content: {}
    }
  },
  methods: {
    onDelete (item) {
      this.currentItem = item
      this.confirmDeleteDialog = true
    },
    onOpenCreateContentDialog () {
      this.createContentDialog = true
    },
    postSiteContentType () {
      this.loading = true
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      postSiteContentType({
        ...this.content,
        tenantId: tenant.tenantId,
        siteId: this.$route.params.siteId
      }, message => {
        if (message.data.code === 1) {
          this.getSiteContentType()
          this.createContentDialog = false
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
      })
    },
    getSiteContentType () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      this.loading = true
      getSiteContentType({
        tenantId: tenant.tenantId,
        siteId: this.$route.params.siteId
      }, message => {
        if (message.data.code === 1) {
          message.data.result.items.forEach(function (element) { element.editable = false })
          this.targetItems = message.data.result.items
        } else {
          this.targetItems = []
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
      })
    },
    fetchData () {
      this.getSiteContentType()
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style>

</style>
