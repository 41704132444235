<template>
 <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
              <label class="text-title-4 natural--text text--darken-4">{{title}} <span v-if="$attrs.required" class="error--text">*</span>
          <v-text-field
            v-bind:value="value"
            append-icon="mdi-calendar"
            readonly
            outlined
            v-bind="attrs"
            :dense="$attrs.dense"
            v-on="on"
            @change="$emit('change',$event)"
          ></v-text-field>
              </label>
        </template>
        <v-date-picker
          v-bind:value="value"
          @change="$emit('change',$event)"
          no-title
          scrollable
          :locale='locale'
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(value)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    title: {
      type: String,
      default: 'Picker in dialog'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array
    },
    locale: {
      type: String,
      default: 'th-th'
    }
  },
  data () {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false
    }
  },
  methods: {
    save (dat) {
      this.$emit('change', dat)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-text-field--outlined ::v-deep fieldset {
  border-color: #DBDCE7;
}
</style>
