<template>
  <v-card :loading="loading">
      <v-card-title class="text-h4 font-weight-bold">สร้างเนื้อหา<v-spacer/><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="text-left">
         <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense class="ma-0 pa-0">
              <v-col cols="12">
                  <!-- <TextField :title="'ประเภทเนื้อหา'" :required="true" v-model="value.contentType" :rules="contentNameRules"/> -->
                  <Select :title="'ประเภทเนื้อหา'" :required="true" :items="contentTypeItems" v-model="value.contentType" item-text="text" item-value="value" :rules="typeRules"/>
              </v-col>
               <v-col cols="12">
                  <Select :title="'รูปแบบความยินยอม'" :rules="typeRules" :required="true" :placeholder="'เลือกประเภท'" :items="consentTypeItems" v-model="value.consentType" item-text="text" item-value="value"/>
              </v-col>
               <v-col cols="12">
                  <TextField type="number" :title="'เวอร์ชั่น'" :required="true" v-model="value.version" :rules="versionRules"/>
              </v-col>
                 <v-col cols="12">
                  <DatePicker :title="'มีผลตั้งแต่'" :required="true" v-model="value.versionDate" :rules="versionDateRules"/>
              </v-col>
                <v-col cols="12">
                 <TextArea :title="'เนื้อหาภาษาไทย'" :required="true" v-model="value.contentTh" :rules="contentTHRules"/>
              </v-col>
               <v-col cols="12">
                 <TextArea :title="'เนื้อหาภาษาอังกฤษ'" v-model="value.contentEn"/>
              </v-col>
          </v-row>
             </v-form>
      </v-card-text>
      <v-card-actions>
     <v-row class="pr-3 pl-3">
              <v-col cols="6" class="pa-2"><v-btn block outlined color="primary" large @click="$emit('onClose')"><span class="text-title-2">ยกเลิก</span></v-btn></v-col>
              <v-col cols="6" class="pa-2"><v-btn block color="primary" large @click="onSubmit()"><span class="text-title-2">บันทึก</span></v-btn></v-col>
          </v-row>
      </v-card-actions>
       <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import TextField from '@/components/common/TextField'
import Select from '@/components/common/Select'
import TextArea from '@/components/common/TextArea'
import DatePicker from '@/components/common/DatePicker'
// import Select from '../common/Select.vue'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean
    },
    formTypeItems: {
      type: Array
    },
    yearItems: {
      type: Array
    },
    value: {
      type: Object
    },
    dialog: {
      type: Boolean
    }
  },
  components: {
    TextField,
    Select,
    TextArea,
    DatePicker
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.$refs.form.reset()
      } else {
        this.value.versionDate = new Date().toISOString().substr(0, 10)
        this.value.version = 1
      }
    }
  },
  data () {
    return {
      name: null,
      type: null,
      loading: false,
      valid: false,
      year: null,
      items: [],
      contentNameRules: [
        v => !!v || 'กรุณาระบุชื่อ'
      ],
      contentTHRules: [
        v => !!v || 'กรุณาระบุเนื้อหาในรูปแบบ Text หรือ HTML'
      ],
      typeRules: [v => !!v || 'กรุณาเลือกประเภท'],
      versionRules: [
        v => !!v || 'กรุณาระบุเวอร์ชั่น'
      ],
      versionDateRules: [
        v => !!v || 'กรุณาระบุวันที่ที่มีผลบังคับใช้'
      ],
      contentTypeItems: [
        { text: 'นโยบายความเป็นส่วนตัว', value: 'privacy_policy' },
        { text: 'เงื่อนไขการให้บริการ', value: 'term_condition' },
        { text: 'แจ้งเพื่อทราบ', value: 'inform' }
      ],
      consentTypeItems: [
        { text: 'แจ้งเพื่อทราบ', value: 'inform' },
        { text: 'ขอความยินยอม', value: 'consent' }
      ],
      contentType: null,
      consentType: null,
      version: 1,
      versionDate: new Date().toISOString().substr(0, 10)
    }
  },
  mounted () {
    this.value.versionDate = new Date().toISOString().substr(0, 10)
    this.value.version = 1
  },
  methods: {
    onSubmit () {
      if (this.$refs.form.validate()) {
        // .year, description: '' })
        this.$emit('onSubmit')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }
     .v-card {
    border-radius: 6px;
    }

</style>
